<template>
  <div>
    <div class="header">
      <div class="title u-font--xlarge">User Groups</div>
      <div>
        <el-button type="primary" size="medium" @click="testUserGroup()"
          >test UserGroups
        </el-button>
        <el-button type="success" size="medium" @click="addUserGroup()"
          >Add new User Group
        </el-button>
      </div>
    </div>
    <div class="body">
      <el-table :data="userGroups" border stripe>
        <el-table-column prop="name" label="Name"> </el-table-column>
        <el-table-column label="Opertaions">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              plain
              @click="deleteUserGroup(scope.row.id)"
              >Delete</el-button
            >
            <el-button
              size="mini"
              type="success"
              icon="el-icon-s-order"
              plain
              @click="viewUserGroupDetails(scope.row.id)"
              >Details</el-button
            >
          </template>
        </el-table-column>
        <!-- <el-table-column label="Id"> </el-table-column> -->
      </el-table>
    </div>
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="pagination.totalResultsCount"
        :current-page="paginationCurrentPage"
        :page-size="25"
        @current-change="handlePagination"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { userGroupService } from "../../services/userGroup.service";
export default {
  data() {
    return {
      userGroups: [],
      pagination: {},
    };
  },

  created() {
    this.getUserGroups();
  },
  computed: {
    currentPage() {
      return this.$route.query.page;
    },
    paginationCurrentPage() {
      return this.pagination.nextPage
        ? this.pagination.nextPage - 1
        : this.pagination.totalPages;
    },
  },
  methods: {
    getUserGroups() {
      this.$loading();

      userGroupService
        .getUserGroups(this.currentPage)
        .then((res) => {
          this.userGroups = res.userGroups;
          this.pagination = res.pagination;
        })
        .finally(() => {
          this.$loading().close();
        });
    },
    deleteUserGroup(id) {
      userGroupService.deleteUserGroup(id).then(() => {
        this.getUserGroups();
      });
    },
    handlePagination(val) {
      const pageLoading = this.$loading();
      userGroupService
        .getUserGroups(val)
        .then((response) => {
          this.userGroups = response.userGroups;
          this.pagination = response.pagination;

          this.$router.push({
            query: {
              page: response.pagination.nextPage
                ? response.pagination.nextPage - 1
                : response.pagination.totalPages,
            },
          });
        })
        .finally(() => {
          pageLoading.close();
        });
    },
    viewUserGroupDetails(id) {
      this.$router.push({
        params: { id: id },
        name: "editUserGroup",
      });
    },
    testUserGroup() {
      this.$router.push({
        name: "testUserGroup",
      });
    },
    addUserGroup() {
      this.$router.push({
        name: "addUserGroup",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.FromToOptionInput {
  display: inline-block !important;

  width: 100px !important;
}
</style>
